var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-card",
        { attrs: { outlined: "" } },
        [
          _c(
            "v-card-title",
            { staticClass: "green lighten-5 mb-2" },
            [
              _c("v-icon", { staticClass: "mr-2", attrs: { color: "green" } }, [
                _vm._v("school")
              ]),
              _c("h5", { staticClass: "title" }, [_vm._v("Formação Acadêmica")])
            ],
            1
          ),
          _c(
            "v-card-text",
            { staticClass: "grey lighten-5" },
            [
              _c(
                "v-row",
                {
                  attrs: { wrap: "", justify: "start", align: "end", dense: "" }
                },
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "12", sm: "12", md: "4" } },
                    [
                      _c("v-autocomplete", {
                        attrs: {
                          dense: "",
                          outlined: "",
                          items: _vm.nivelFormacao,
                          attach: "",
                          "item-text": "nivelFormacao",
                          label: "Nível de Formação*",
                          placeholder: "(Escolha uma opção)",
                          rules: _vm.rules
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "no-data",
                            fn: function() {
                              return [
                                _c(
                                  "v-list-item",
                                  [
                                    _c("v-list-item-title", [
                                      _vm._v(" Nível inválido. ")
                                    ])
                                  ],
                                  1
                                )
                              ]
                            },
                            proxy: true
                          }
                        ]),
                        model: {
                          value: _vm.candidate.nivelFormacao,
                          callback: function($$v) {
                            _vm.$set(_vm.candidate, "nivelFormacao", $$v)
                          },
                          expression: "candidate.nivelFormacao"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "12", sm: "12", md: "8" } },
                    [
                      _c("v-combobox", {
                        attrs: {
                          dense: "",
                          outlined: "",
                          items: _vm.nomeDaInstituicao,
                          attach: "",
                          "item-text": "Nome da Instituição",
                          label: "Instituição de Ensino Superior",
                          "persistent-hint": "",
                          hint: "Formato: Nome (SIGLA)",
                          rules: _vm.rules
                        },
                        model: {
                          value: _vm.candidate.nomeDaInstituicao,
                          callback: function($$v) {
                            _vm.$set(_vm.candidate, "nomeDaInstituicao", $$v)
                          },
                          expression: "candidate.nomeDaInstituicao"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "12", sm: "12", md: "5" } },
                    [
                      _c("v-text-field", {
                        attrs: {
                          dense: "",
                          outlined: "",
                          block: "",
                          label: "Curso*",
                          isProgressBarVisible: _vm.isProgressBarVisible,
                          rules: _vm.rules
                        },
                        model: {
                          value: _vm.candidate.cursoSuperior,
                          callback: function($$v) {
                            _vm.$set(_vm.candidate, "cursoSuperior", $$v)
                          },
                          expression: "candidate.cursoSuperior"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "12", sm: "12", md: "5" } },
                    [
                      _c("v-text-field", {
                        attrs: {
                          dense: "",
                          outlined: "",
                          block: "",
                          label: "Cidade da Instituição*",
                          isProgressBarVisible: _vm.isProgressBarVisible,
                          rules: _vm.rules
                        },
                        model: {
                          value: _vm.candidate.cidadeInstituicao,
                          callback: function($$v) {
                            _vm.$set(_vm.candidate, "cidadeInstituicao", $$v)
                          },
                          expression: "candidate.cidadeInstituicao"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "12", sm: "12", md: "2" } },
                    [
                      _c("v-autocomplete", {
                        attrs: {
                          dense: "",
                          outlined: "",
                          items: _vm.ufs,
                          attach: "",
                          "item-text": "UF",
                          label: "UF Instituição*",
                          rules: _vm.rules
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "no-data",
                            fn: function() {
                              return [
                                _c(
                                  "v-list-item",
                                  [
                                    _c("v-list-item-title", [
                                      _vm._v(" UF não encontrada. ")
                                    ])
                                  ],
                                  1
                                )
                              ]
                            },
                            proxy: true
                          }
                        ]),
                        model: {
                          value: _vm.candidate.estadoInstituicao,
                          callback: function($$v) {
                            _vm.$set(_vm.candidate, "estadoInstituicao", $$v)
                          },
                          expression: "candidate.estadoInstituicao"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "12", sm: "12", md: "3" } },
                    [
                      _c("v-autocomplete", {
                        attrs: {
                          dense: "",
                          outlined: "",
                          items: _vm.countries,
                          attach: "",
                          label: "País Instituição*",
                          rules: _vm.rules
                        },
                        model: {
                          value: _vm.candidate.paisInstituicao,
                          callback: function($$v) {
                            _vm.$set(_vm.candidate, "paisInstituicao", $$v)
                          },
                          expression: "candidate.paisInstituicao"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "12", sm: "12", md: "3" } },
                    [
                      _c("v-text-field", {
                        directives: [
                          {
                            name: "mask",
                            rawName: "v-mask",
                            value: "##/##/####",
                            expression: "'##/##/####'"
                          }
                        ],
                        attrs: {
                          dense: "",
                          outlined: "",
                          block: "",
                          label: "Data de Conclusão/Previsão de Conclusão*",
                          hint: "Formato: dd/mm/aaaa",
                          isProgressBarVisible: _vm.isProgressBarVisible,
                          rules: _vm.rules
                        },
                        model: {
                          value: _vm.candidate.dataConclusao,
                          callback: function($$v) {
                            _vm.$set(_vm.candidate, "dataConclusao", $$v)
                          },
                          expression: "candidate.dataConclusao"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "12", sm: "12", md: "3" } },
                    [
                      _c("v-text-field", {
                        directives: [
                          {
                            name: "mask",
                            rawName: "v-mask",
                            value: "##",
                            expression: "'##'"
                          }
                        ],
                        attrs: {
                          dense: "",
                          outlined: "",
                          block: "",
                          label: "Número do Semestre Atual",
                          isProgressBarVisible: _vm.isProgressBarVisible,
                          rules: _vm.rules
                        },
                        model: {
                          value: _vm.candidate.numeroSemestreAtual,
                          callback: function($$v) {
                            _vm.$set(_vm.candidate, "numeroSemestreAtual", $$v)
                          },
                          expression: "candidate.numeroSemestreAtual"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "12", sm: "12", md: "3" } },
                    [
                      _c("v-select", {
                        attrs: {
                          dense: "",
                          outlined: "",
                          items: _vm.binaryChoice,
                          label:
                            "Você já se inscreveu anteriormente para aluno especial no IC?*"
                        },
                        model: {
                          value: _vm.jaSeInscreveuParaAlunoEspecial,
                          callback: function($$v) {
                            _vm.jaSeInscreveuParaAlunoEspecial = $$v
                          },
                          expression: "jaSeInscreveuParaAlunoEspecial"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "12", sm: "12", md: "5" } },
                    [
                      _vm.jaSeInscreveuParaAlunoEspecial
                        ? _c("v-select", {
                            attrs: {
                              dense: "",
                              outlined: "",
                              items: _vm.statusUltimoSemestreEspecial,
                              label: "Se sim, você:*",
                              placeholder: "(Escolha uma opção)"
                            },
                            model: {
                              value: _vm.candidate.statusUltimoSemestreEspecial,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.candidate,
                                  "statusUltimoSemestreEspecial",
                                  $$v
                                )
                              },
                              expression:
                                "candidate.statusUltimoSemestreEspecial"
                            }
                          })
                        : _vm._e()
                    ],
                    1
                  ),
                  _c("v-col", { attrs: { sm: "0", md: "7" } })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }