var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-row",
    { attrs: { justify: "start", align: "center", dense: "" } },
    [
      _c("v-col", { attrs: { cols: "12" } }, [
        _c("h3", [_vm._v("Alterar Dados")])
      ]),
      _c(
        "v-col",
        { attrs: { cols: "12" } },
        [_c("CandidatePersonalData", { attrs: { candidate: _vm.candidate } })],
        1
      ),
      _c(
        "v-col",
        { attrs: { cols: "12" } },
        [
          _c("CandidateAddress", {
            staticClass: "mt-8",
            attrs: { candidate: _vm.candidate }
          })
        ],
        1
      ),
      _c(
        "v-col",
        { attrs: { cols: "12" } },
        [
          _c("CandidateAcademicInformation", {
            staticClass: "mt-8",
            attrs: { candidate: _vm.candidate }
          })
        ],
        1
      ),
      _c(
        "v-col",
        { attrs: { cols: "12" } },
        [
          _c("CandidatePosComp", {
            staticClass: "mt-8",
            attrs: { candidate: _vm.candidate }
          })
        ],
        1
      ),
      _c(
        "v-row",
        {
          staticClass: "mt-5",
          attrs: { wrap: "", justify: "center", align: "center" }
        },
        [
          _c(
            "v-col",
            {
              staticClass: "text-center",
              attrs: { cols: "12", sm: "12", md: "6" }
            },
            [
              _c(
                "v-btn",
                {
                  staticClass: "py-6 my-5",
                  attrs: {
                    outlined: "",
                    color: "red accent-2",
                    label: "Cancelar"
                  },
                  on: {
                    click: function($event) {
                      return _vm.cancel()
                    }
                  }
                },
                [
                  _vm._v("Cancelar"),
                  _c(
                    "v-icon",
                    { staticClass: "ml-2", attrs: { color: "red" } },
                    [_vm._v("cancel")]
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-col",
            {
              staticClass: "text-center",
              attrs: { cols: "12", sm: "12", md: "6" }
            },
            [
              _c(
                "v-btn",
                {
                  staticClass: "py-6 my-5",
                  attrs: {
                    dense: "",
                    outlined: "",
                    color: "blue accent-2",
                    name: "signup-btn",
                    label: "Cadastrar",
                    type: "submit",
                    disabled: _vm.invalid,
                    isProgressBarVisible: _vm.isProgressBarVisible
                  },
                  on: {
                    click: function($event) {
                      return _vm.submitForm()
                    }
                  }
                },
                [
                  _vm._v("Atualizar"),
                  _c(
                    "v-icon",
                    { staticClass: "ml-2", attrs: { color: "blue" } },
                    [_vm._v("done")]
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "12", sm: "12" } },
            [
              _c("v-progress-linear", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.isProgressBarVisible,
                    expression: "isProgressBarVisible"
                  }
                ],
                attrs: { color: "blue accent-2", indeterminate: "" }
              })
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "12", sm: "12" } },
            [
              _c("v-label", { attrs: { color: "red" } }, [
                _vm._v(_vm._s(_vm.message))
              ])
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-snackbar",
        {
          attrs: { timeout: _vm.timeout },
          model: {
            value: _vm.snackbar,
            callback: function($$v) {
              _vm.snackbar = $$v
            },
            expression: "snackbar"
          }
        },
        [
          _c("v-row", { attrs: { justify: "center" } }, [
            _vm._v(" " + _vm._s(_vm.snackBarMessage) + " ")
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }