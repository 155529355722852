<template>
  <v-row justify="start" align="center" dense>
    <v-col cols="12">
      <h3>Alterar Dados</h3>
    </v-col>
    <v-col cols="12">
      <CandidatePersonalData :candidate="candidate"></CandidatePersonalData>
    </v-col>

    <v-col cols="12">
      <CandidateAddress class="mt-8" :candidate="candidate"></CandidateAddress>
    </v-col>

    <v-col cols="12">
      <CandidateAcademicInformation
        class="mt-8"
        :candidate="candidate"
      ></CandidateAcademicInformation>
    </v-col>

    <v-col cols="12">
      <CandidatePosComp class="mt-8" :candidate="candidate"></CandidatePosComp>
    </v-col>

    <v-row wrap justify="center" align="center" class="mt-5">
      <v-col cols="12" sm="12" md="6" class="text-center">
        <v-btn
          outlined
          color="red accent-2"
          label="Cancelar"
          class="py-6 my-5"
          @click="cancel()"
          >Cancelar<v-icon color="red" class="ml-2">cancel</v-icon></v-btn
        >
      </v-col>
      <v-col cols="12" sm="12" md="6" class="text-center">
        <v-btn
          dense
          outlined
          color="blue accent-2"
          name="signup-btn"
          label="Cadastrar"
          class="py-6 my-5"
          type="submit"
          @click="submitForm()"
          :disabled="invalid"
          :isProgressBarVisible="isProgressBarVisible"
          >Atualizar<v-icon color="blue" class="ml-2">done</v-icon></v-btn
        >
      </v-col>
      <v-col cols="12" sm="12">
        <v-progress-linear
          color="blue accent-2"
          indeterminate
          v-show="isProgressBarVisible"
        ></v-progress-linear>
      </v-col>
      <v-col cols="12" sm="12">
        <v-label color="red">{{ message }}</v-label>
      </v-col>
    </v-row>

    <v-snackbar v-model="snackbar" :timeout="timeout">
      <v-row justify="center">
        {{ snackBarMessage }}
      </v-row>
    </v-snackbar>
  </v-row>
</template>


<script>
// /* eslint-disable vue/no-unused-components */
import { mapState, mapActions } from "vuex";
import * as storage from "@/pages/auth/storage";

import CandidateAddress from "./CandidateAddress";
import CandidatePersonalData from "./CandidatePersonalData";
import CandidateAcademicInformation from "./CandidateAcademicInformation";
import CandidatePosComp from "./CandidatePosComp";

export default {
  name: "EditCandidate",
  props: ["id"],
  components: {
    CandidateAddress,
    CandidatePosComp,
    CandidatePersonalData,
    CandidateAcademicInformation,
  },
  data() {
    return {
      message: "",
      isProgressBarVisible: false,
      invalid: false,
      snackbar: false,
      timeout: 3000,
      snackBarMessage: "",
    };
  },

  created() {
    if (this.id == undefined) {
      let username = storage.getLocalData().user.username;
      this.getAsyncCandidate(username);
    }
  },

  computed: {
    ...mapState("editCandidate", ["candidate"]),
  },

  methods: {
    ...mapActions("editCandidate", [
      "ActionGetCandidate",
      "ActionPutCandidate",
    ]),

    cancel() {
      this.$router.push({ name: "home" });
    },

    async getAsyncCandidate(username) {
      try {
        await this.ActionGetCandidate(username);
      } catch (error) {
        this.snackBarMessage =
          "Erro ao buscar o usuário no sistema! Tente mais tarde.";
        this.snackbar = true;
        console.error(
          "Erro ao buscar o usuário no sistema! Tente mais tarde.",
          error
        );
      }
    },

    async submitForm() {
      try {
        this.isProgressBarVisible = true;
        await this.ActionPutCandidate(this.candidate);
        this.snackBarMessage = "Dados atualizados com sucesso!";
      } catch (error) {
        console.error(error.body.errors);
        this.snackBarMessage =
          "Erro ao atualizar o usuário no sistema! Tente mais tarde. " +
          error.body.message;
      } finally {
        this.snackbar = true;
        this.isProgressBarVisible = false;
      }
    },
  },
};
</script>

<style>
.avatar {
  text-align: center;
  margin: 0 auto;
}
</style>
