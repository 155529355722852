var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-card",
        { attrs: { outlined: "" } },
        [
          _c(
            "v-card-title",
            { staticClass: "blue lighten-5 mb-2" },
            [
              _c("v-icon", { staticClass: "mr-2", attrs: { color: "blue" } }, [
                _vm._v("person")
              ]),
              _c("h5", { staticClass: "title" }, [_vm._v("Dados Pessoais")])
            ],
            1
          ),
          _c(
            "v-card-text",
            { staticClass: "grey lighten-5" },
            [
              _c(
                "v-row",
                {
                  attrs: {
                    wrap: "",
                    justify: "start",
                    align: "start",
                    dense: ""
                  }
                },
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "12", sm: "12", md: "2" } },
                    [
                      _c("v-text-field", {
                        attrs: {
                          dense: "",
                          outlined: "",
                          block: "",
                          label: "RA",
                          placeholder: "RA, se for estudante Unicamp",
                          rules: _vm.rules
                        },
                        model: {
                          value: _vm.candidate.raCandidate,
                          callback: function($$v) {
                            _vm.$set(_vm.candidate, "raCandidate", $$v)
                          },
                          expression: "candidate.raCandidate"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-row",
                {
                  attrs: {
                    wrap: "",
                    justify: "start",
                    align: "start",
                    dense: ""
                  }
                },
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "12", sm: "12", md: "5" } },
                    [
                      _c("v-text-field", {
                        attrs: {
                          dense: "",
                          outlined: "",
                          block: "",
                          label: "Nome completo*",
                          placeholder: "Nome do completo do candidato",
                          isProgressBarVisible: _vm.isProgressBarVisible,
                          rules: _vm.rules
                        },
                        model: {
                          value: _vm.candidate.name,
                          callback: function($$v) {
                            _vm.$set(_vm.candidate, "name", $$v)
                          },
                          expression: "candidate.name"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "12", sm: "12", md: "4" } },
                    [
                      _c("v-text-field", {
                        attrs: {
                          dense: "",
                          outlined: "",
                          block: "",
                          label: "E-mail*",
                          isProgressBarVisible: _vm.isProgressBarVisible,
                          rules: _vm.rules
                        },
                        model: {
                          value: _vm.candidate.email,
                          callback: function($$v) {
                            _vm.$set(_vm.candidate, "email", $$v)
                          },
                          expression: "candidate.email"
                        }
                      })
                    ],
                    1
                  ),
                  _c("v-col", { attrs: { cols: "12", sm: "12", md: "3" } }),
                  _c(
                    "v-col",
                    { attrs: { cols: "12", sm: "12", md: "2" } },
                    [
                      _c("v-text-field", {
                        directives: [
                          {
                            name: "mask",
                            rawName: "v-mask",
                            value: "##/##/####",
                            expression: "'##/##/####'"
                          }
                        ],
                        attrs: {
                          dense: "",
                          outlined: "",
                          block: "",
                          label: "Data de nascimento*",
                          hint: "Formato: dd/mm/aaaa",
                          isProgressBarVisible: _vm.isProgressBarVisible,
                          rules: _vm.rules
                        },
                        model: {
                          value: _vm.candidate.birthDate,
                          callback: function($$v) {
                            _vm.$set(_vm.candidate, "birthDate", $$v)
                          },
                          expression: "candidate.birthDate"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "12", sm: "12", md: "2" } },
                    [
                      _c("v-select", {
                        attrs: {
                          dense: "",
                          outlined: "",
                          items: _vm.sexes,
                          label: "Sexo*",
                          rules: _vm.rules
                        },
                        model: {
                          value: _vm.candidate.sex,
                          callback: function($$v) {
                            _vm.$set(_vm.candidate, "sex", $$v)
                          },
                          expression: "candidate.sex"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "12", sm: "12", md: "2" } },
                    [
                      _c("v-select", {
                        attrs: {
                          dense: "",
                          outlined: "",
                          items: _vm.civilStatuses,
                          label: "Estado Civil*",
                          rules: _vm.rules
                        },
                        model: {
                          value: _vm.candidate.civilStatus,
                          callback: function($$v) {
                            _vm.$set(_vm.candidate, "civilStatus", $$v)
                          },
                          expression: "candidate.civilStatus"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "12", sm: "12", md: "2" } },
                    [
                      _c("v-select", {
                        attrs: {
                          dense: "",
                          outlined: "",
                          items: _vm.binaryChoice,
                          label: "Atualmente, está trabalhando?*",
                          rules: _vm.rules
                        },
                        model: {
                          value: _vm.isEmployed,
                          callback: function($$v) {
                            _vm.isEmployed = $$v
                          },
                          expression: "isEmployed"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "12", sm: "12", md: "4" } },
                    [
                      _vm.isEmployed === true
                        ? _c("v-text-field", {
                            attrs: {
                              dense: "",
                              outlined: "",
                              block: "",
                              label: "Nome da Empresa*",
                              isProgressBarVisible: _vm.isProgressBarVisible,
                              rules: _vm.rules
                            },
                            model: {
                              value: _vm.candidate.company,
                              callback: function($$v) {
                                _vm.$set(_vm.candidate, "company", $$v)
                              },
                              expression: "candidate.company"
                            }
                          })
                        : _vm._e()
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "12", sm: "12", md: "2" } },
                    [
                      _c("v-autocomplete", {
                        attrs: {
                          dense: "",
                          outlined: "",
                          items: _vm.countries,
                          attach: "",
                          label: "País de origem*",
                          placeholder: "Escolha um país",
                          rules: _vm.rules
                        },
                        model: {
                          value: _vm.candidate.country,
                          callback: function($$v) {
                            _vm.$set(_vm.candidate, "country", $$v)
                          },
                          expression: "candidate.country"
                        }
                      })
                    ],
                    1
                  ),
                  _vm.candidate.country === "Brasil"
                    ? _c(
                        "v-col",
                        { attrs: { cols: "12", sm: "12", md: "3" } },
                        [
                          _c("v-text-field", {
                            directives: [
                              {
                                name: "mask",
                                rawName: "v-mask",
                                value: "###.###.###-##",
                                expression: "'###.###.###-##'"
                              }
                            ],
                            attrs: {
                              dense: "",
                              outlined: "",
                              block: "",
                              label: "CPF*",
                              isProgressBarVisible: _vm.isProgressBarVisible,
                              rules: _vm.rules
                            },
                            model: {
                              value: _vm.candidate.cpf,
                              callback: function($$v) {
                                _vm.$set(_vm.candidate, "cpf", $$v)
                              },
                              expression: "candidate.cpf"
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.candidate.country !== "Brasil"
                    ? _c(
                        "v-col",
                        { attrs: { cols: "12", sm: "12", md: "5" } },
                        [
                          _c("v-select", {
                            attrs: {
                              dense: "",
                              outlined: "",
                              items: _vm.binaryChoice,
                              label: "Adquiriu nacionalidade brasileira?*",
                              rules: _vm.rules
                            },
                            model: {
                              value: _vm.isForeignerWithBrazilianNacionality,
                              callback: function($$v) {
                                _vm.isForeignerWithBrazilianNacionality = $$v
                              },
                              expression: "isForeignerWithBrazilianNacionality"
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.candidate.country !== "Brasil"
                    ? _c(
                        "v-col",
                        { attrs: { cols: "12", sm: "12", md: "5" } },
                        [
                          _c("v-text-field", {
                            attrs: {
                              dense: "",
                              outlined: "",
                              block: "",
                              label: "RNE/CRNM*",
                              isProgressBarVisible: _vm.isProgressBarVisible,
                              rules: _vm.rules
                            },
                            model: {
                              value: _vm.candidate.foreignId,
                              callback: function($$v) {
                                _vm.$set(_vm.candidate, "foreignId", $$v)
                              },
                              expression: "candidate.foreignId"
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "v-col",
                    { attrs: { cols: "12", sm: "12", md: "12" } },
                    [
                      _c("v-select", {
                        attrs: {
                          dense: "",
                          outlined: "",
                          items: _vm.tipoDeAluno,
                          label: "Tipo de Aluno*",
                          placeholder: "(Escolha uma opção)"
                        },
                        model: {
                          value: _vm.candidate.tipoDeAluno,
                          callback: function($$v) {
                            _vm.$set(_vm.candidate, "tipoDeAluno", $$v)
                          },
                          expression: "candidate.tipoDeAluno"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }