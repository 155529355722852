var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-card",
        { attrs: { outlined: "" } },
        [
          _c(
            "v-card-title",
            { staticClass: "amber lighten-5 mb-2" },
            [
              _c("v-icon", { staticClass: "mr-2", attrs: { color: "amber" } }, [
                _vm._v("mail")
              ]),
              _c("h5", { staticClass: "title" }, [
                _vm._v("Dados para envio de correspondência")
              ])
            ],
            1
          ),
          _c(
            "v-card-text",
            { staticClass: "grey lighten-5" },
            [
              _c(
                "v-row",
                {
                  attrs: { wrap: "", justify: "start", align: "end", dense: "" }
                },
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "12", sm: "12", md: "2" } },
                    [
                      _c("v-text-field", {
                        directives: [
                          {
                            name: "mask",
                            rawName: "v-mask",
                            value: "#####-###",
                            expression: "'#####-###'"
                          }
                        ],
                        attrs: {
                          dense: "",
                          outlined: "",
                          block: "",
                          label: "CEP*",
                          isProgressBarVisible: _vm.isProgressBarVisible
                        },
                        on: {
                          change: function($event) {
                            return _vm.getAddressFromAPI(
                              _vm.candidate.primary_cep,
                              true
                            )
                          }
                        },
                        model: {
                          value: _vm.candidate.primary_cep,
                          callback: function($$v) {
                            _vm.$set(_vm.candidate, "primary_cep", $$v)
                          },
                          expression: "candidate.primary_cep"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "12", sm: "12", md: "2" } },
                    [
                      _c("v-autocomplete", {
                        attrs: {
                          dense: "",
                          outlined: "",
                          items: _vm.ufs,
                          attach: "",
                          "item-text": "UF",
                          label: "UF*"
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "no-data",
                            fn: function() {
                              return [
                                _c(
                                  "v-list-item",
                                  [
                                    _c("v-list-item-title", [
                                      _vm._v(" UF não encontrada. ")
                                    ])
                                  ],
                                  1
                                )
                              ]
                            },
                            proxy: true
                          }
                        ]),
                        model: {
                          value: _vm.candidate.primary_state,
                          callback: function($$v) {
                            _vm.$set(_vm.candidate, "primary_state", $$v)
                          },
                          expression: "candidate.primary_state"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "12", sm: "12", md: "8" } },
                    [
                      _c("v-text-field", {
                        attrs: {
                          dense: "",
                          outlined: "",
                          block: "",
                          label: "Cidade*",
                          isProgressBarVisible: _vm.isProgressBarVisible
                        },
                        model: {
                          value: _vm.candidate.primary_city,
                          callback: function($$v) {
                            _vm.$set(_vm.candidate, "primary_city", $$v)
                          },
                          expression: "candidate.primary_city"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "12", sm: "12", md: "5" } },
                    [
                      _c("v-text-field", {
                        attrs: {
                          dense: "",
                          outlined: "",
                          block: "",
                          label: "Rua/Avenida*",
                          isProgressBarVisible: _vm.isProgressBarVisible
                        },
                        model: {
                          value: _vm.candidate.primary_street,
                          callback: function($$v) {
                            _vm.$set(_vm.candidate, "primary_street", $$v)
                          },
                          expression: "candidate.primary_street"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "12", sm: "12", md: "2" } },
                    [
                      _c("v-text-field", {
                        directives: [
                          {
                            name: "mask",
                            rawName: "v-mask",
                            value: "#########",
                            expression: "'#########'"
                          }
                        ],
                        ref: "homeNumber",
                        attrs: {
                          dense: "",
                          outlined: "",
                          block: "",
                          label: "Número*",
                          isProgressBarVisible: _vm.isProgressBarVisible
                        },
                        model: {
                          value: _vm.candidate.primary_home_number,
                          callback: function($$v) {
                            _vm.$set(_vm.candidate, "primary_home_number", $$v)
                          },
                          expression: "candidate.primary_home_number"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "12", sm: "12", md: "5" } },
                    [
                      _c("v-text-field", {
                        attrs: {
                          dense: "",
                          outlined: "",
                          block: "",
                          label: "Complemento",
                          isProgressBarVisible: _vm.isProgressBarVisible
                        },
                        model: {
                          value: _vm.candidate.primary_complement,
                          callback: function($$v) {
                            _vm.$set(_vm.candidate, "primary_complement", $$v)
                          },
                          expression: "candidate.primary_complement"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "12", sm: "12", md: "3" } },
                    [
                      _c("v-text-field", {
                        directives: [
                          {
                            name: "mask",
                            rawName: "v-mask",
                            value: "(##) #####-####",
                            expression: "'(##) #####-####'"
                          }
                        ],
                        attrs: {
                          dense: "",
                          outlined: "",
                          block: "",
                          label: "Telefone de contato*",
                          isProgressBarVisible: _vm.isProgressBarVisible
                        },
                        model: {
                          value: _vm.candidate.primary_cell_phone,
                          callback: function($$v) {
                            _vm.$set(_vm.candidate, "primary_cell_phone", $$v)
                          },
                          expression: "candidate.primary_cell_phone"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "12", sm: "12", md: "3" } },
                    [
                      _c("v-select", {
                        attrs: {
                          dense: "",
                          outlined: "",
                          items: _vm.binaryChoice,
                          label:
                            "Gostaria de adicionar um endereço alternativo, por segurança?*"
                        },
                        model: {
                          value: _vm.shouldAddAnotherAddress,
                          callback: function($$v) {
                            _vm.shouldAddAnotherAddress = $$v
                          },
                          expression: "shouldAddAnotherAddress"
                        }
                      })
                    ],
                    1
                  ),
                  _c("v-col", { attrs: { sm: "0", md: "6" } }),
                  _c(
                    "v-col",
                    { attrs: { cols: "12", sm: "12", md: "2" } },
                    [
                      _c("v-text-field", {
                        directives: [
                          {
                            name: "mask",
                            rawName: "v-mask",
                            value: "#####-###",
                            expression: "'#####-###'"
                          }
                        ],
                        attrs: {
                          dense: "",
                          outlined: "",
                          block: "",
                          label: "CEP*",
                          isProgressBarVisible: _vm.isProgressBarVisible
                        },
                        on: {
                          change: function($event) {
                            return _vm.getAddressFromAPI(
                              _vm.candidate.secondary_cep,
                              false
                            )
                          }
                        },
                        model: {
                          value: _vm.candidate.secondary_cep,
                          callback: function($$v) {
                            _vm.$set(_vm.candidate, "secondary_cep", $$v)
                          },
                          expression: "candidate.secondary_cep"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "12", sm: "12", md: "1" } },
                    [
                      _c("v-autocomplete", {
                        attrs: {
                          dense: "",
                          outlined: "",
                          items: _vm.ufs,
                          attach: "",
                          "item-text": "UF",
                          label: "UF*"
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "no-data",
                            fn: function() {
                              return [
                                _c(
                                  "v-list-item",
                                  [
                                    _c("v-list-item-title", [
                                      _vm._v(" UF não encontrada. ")
                                    ])
                                  ],
                                  1
                                )
                              ]
                            },
                            proxy: true
                          }
                        ]),
                        model: {
                          value: _vm.candidate.secondary_state,
                          callback: function($$v) {
                            _vm.$set(_vm.candidate, "secondary_state", $$v)
                          },
                          expression: "candidate.secondary_state"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "12", sm: "12", md: "5" } },
                    [
                      _c("v-text-field", {
                        attrs: {
                          dense: "",
                          outlined: "",
                          block: "",
                          label: "Cidade*",
                          isProgressBarVisible: _vm.isProgressBarVisible
                        },
                        model: {
                          value: _vm.candidate.secondary_city,
                          callback: function($$v) {
                            _vm.$set(_vm.candidate, "secondary_city", $$v)
                          },
                          expression: "candidate.secondary_city"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "12", sm: "12", md: "4" } },
                    [
                      _c("v-text-field", {
                        attrs: {
                          dense: "",
                          outlined: "",
                          block: "",
                          label: "Rua/Avenida*",
                          isProgressBarVisible: _vm.isProgressBarVisible
                        },
                        model: {
                          value: _vm.candidate.secondary_street,
                          callback: function($$v) {
                            _vm.$set(_vm.candidate, "secondary_street", $$v)
                          },
                          expression: "candidate.secondary_street"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "12", sm: "12", md: "2" } },
                    [
                      _c("v-text-field", {
                        directives: [
                          {
                            name: "mask",
                            rawName: "v-mask",
                            value: "#########",
                            expression: "'#########'"
                          }
                        ],
                        ref: "homeNumber",
                        attrs: {
                          dense: "",
                          outlined: "",
                          block: "",
                          label: "Número*",
                          isProgressBarVisible: _vm.isProgressBarVisible
                        },
                        model: {
                          value: _vm.candidate.secondary_home_number,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.candidate,
                              "secondary_home_number",
                              $$v
                            )
                          },
                          expression: "candidate.secondary_home_number"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "12", sm: "12", md: "4" } },
                    [
                      _c("v-text-field", {
                        attrs: {
                          dense: "",
                          outlined: "",
                          block: "",
                          label: "Complemento",
                          isProgressBarVisible: _vm.isProgressBarVisible
                        },
                        model: {
                          value: _vm.candidate.secondary_complement,
                          callback: function($$v) {
                            _vm.$set(_vm.candidate, "secondary_complement", $$v)
                          },
                          expression: "candidate.secondary_complement"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "12", sm: "12", md: "2" } },
                    [
                      _c("v-text-field", {
                        directives: [
                          {
                            name: "mask",
                            rawName: "v-mask",
                            value: "(##) #####-####",
                            expression: "'(##) #####-####'"
                          }
                        ],
                        attrs: {
                          dense: "",
                          outlined: "",
                          block: "",
                          label: "Telefone*",
                          isProgressBarVisible: _vm.isProgressBarVisible
                        },
                        model: {
                          value: _vm.candidate.secondary_cell_phone,
                          callback: function($$v) {
                            _vm.$set(_vm.candidate, "secondary_cell_phone", $$v)
                          },
                          expression: "candidate.secondary_cell_phone"
                        }
                      })
                    ],
                    1
                  ),
                  _c("v-col", { attrs: { sm: "0", md: "4" } })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }