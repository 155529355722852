<template>
  <div>
    <v-card outlined>
      <v-card-title class="red lighten-5 mb-2"
        ><v-icon class="mr-2" color="red">description</v-icon>
        <h5 class="title">PósComp</h5></v-card-title
      >
      <v-card-text class="grey lighten-5">
        <v-row wrap justify="start" align="end" dense>
          <v-col cols="12" sm="12">
            <div>
              O
              <strong
                ><a
                  href="https://www.sbc.org.br/noticias/10-slideshow-noticias/1971-faq-do-poscomp"
                  target="_blank"
                >
                  POSCOMP</a
                ></strong
              >
              é um exame nacional para ingresso na pós-graduação em Ciência da
              Computação. O exame é aplicado anualmente no segundo semestre.
              Recomendamos a todos os candidatos a aluno especial já graduados
              que façam o POSCOMP. Como o exame é aplicado apenas no segundo
              semestre, sabemos que vários candidatos não tiveram a oportunidade
              de realizá-lo e, por isso mesmo, o POSCOMP não é considerado
              obrigatório para avaliação. No entanto, recomendamos que todos os
              candidatos se programem para realizar o POSCOMP na próxima
              oportunidade possível.
            </div>
          </v-col>
          <v-col cols="12">
            <v-row wrap>
              <v-col cols="12" sm="12" md="6">
                <v-text-field
                  dense
                  outlined
                  block
                  name="poscompNumber"
                  label="Número de inscrição"
                  v-model="candidate.poscompNumber"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="12" md="6">
                <v-text-field
                  dense
                  outlined
                  block
                  name="poscompYear"
                  label="Ano de Realização :"
                  type="number"
                  hint="Ex.: 2000"
                  v-model="candidate.poscompYear"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
export default {
  name: "UpdatePosComp",
  props: ["candidate"],

  data() {
    return {};
  },
};
</script>

<style>
</style>