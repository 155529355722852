var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-card",
        { attrs: { outlined: "" } },
        [
          _c(
            "v-card-title",
            { staticClass: "red lighten-5 mb-2" },
            [
              _c("v-icon", { staticClass: "mr-2", attrs: { color: "red" } }, [
                _vm._v("description")
              ]),
              _c("h5", { staticClass: "title" }, [_vm._v("PósComp")])
            ],
            1
          ),
          _c(
            "v-card-text",
            { staticClass: "grey lighten-5" },
            [
              _c(
                "v-row",
                {
                  attrs: { wrap: "", justify: "start", align: "end", dense: "" }
                },
                [
                  _c("v-col", { attrs: { cols: "12", sm: "12" } }, [
                    _c("div", [
                      _vm._v(" O "),
                      _c("strong", [
                        _c(
                          "a",
                          {
                            attrs: {
                              href:
                                "https://www.sbc.org.br/noticias/10-slideshow-noticias/1971-faq-do-poscomp",
                              target: "_blank"
                            }
                          },
                          [_vm._v(" POSCOMP")]
                        )
                      ]),
                      _vm._v(
                        " é um exame nacional para ingresso na pós-graduação em Ciência da Computação. O exame é aplicado anualmente no segundo semestre. Recomendamos a todos os candidatos a aluno especial já graduados que façam o POSCOMP. Como o exame é aplicado apenas no segundo semestre, sabemos que vários candidatos não tiveram a oportunidade de realizá-lo e, por isso mesmo, o POSCOMP não é considerado obrigatório para avaliação. No entanto, recomendamos que todos os candidatos se programem para realizar o POSCOMP na próxima oportunidade possível. "
                      )
                    ])
                  ]),
                  _c(
                    "v-col",
                    { attrs: { cols: "12" } },
                    [
                      _c(
                        "v-row",
                        { attrs: { wrap: "" } },
                        [
                          _c(
                            "v-col",
                            { attrs: { cols: "12", sm: "12", md: "6" } },
                            [
                              _c("v-text-field", {
                                attrs: {
                                  dense: "",
                                  outlined: "",
                                  block: "",
                                  name: "poscompNumber",
                                  label: "Número de inscrição"
                                },
                                model: {
                                  value: _vm.candidate.poscompNumber,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.candidate,
                                      "poscompNumber",
                                      $$v
                                    )
                                  },
                                  expression: "candidate.poscompNumber"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            { attrs: { cols: "12", sm: "12", md: "6" } },
                            [
                              _c("v-text-field", {
                                attrs: {
                                  dense: "",
                                  outlined: "",
                                  block: "",
                                  name: "poscompYear",
                                  label: "Ano de Realização :",
                                  type: "number",
                                  hint: "Ex.: 2000"
                                },
                                model: {
                                  value: _vm.candidate.poscompYear,
                                  callback: function($$v) {
                                    _vm.$set(_vm.candidate, "poscompYear", $$v)
                                  },
                                  expression: "candidate.poscompYear"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }