<template>
  <div>
    <v-card outlined>
      <v-card-title class="blue lighten-5 mb-2"
        ><v-icon class="mr-2" color="blue">person</v-icon>
        <h5 class="title">Dados Pessoais</h5></v-card-title
      >
      <v-card-text class="grey lighten-5">
        <v-row wrap justify="start" align="start" dense>
          <v-col cols="12" sm="12" md="2">
            <v-text-field
              dense
              outlined
              block
              label="RA"
              placeholder="RA, se for estudante Unicamp"
              v-model="candidate.raCandidate"
              :rules="rules"
            ></v-text-field
          ></v-col>
        </v-row>
        <v-row wrap justify="start" align="start" dense>
          <v-col cols="12" sm="12" md="5">
            <v-text-field
              dense
              outlined
              block
              label="Nome completo*"
              placeholder="Nome do completo do candidato"
              v-model="candidate.name"
              :isProgressBarVisible="isProgressBarVisible"
              :rules="rules"
            ></v-text-field>
          </v-col>

         <v-col cols="12" sm="12" md="4">
            <v-text-field
              dense
              outlined
              block
              label="E-mail*"
              v-model="candidate.email"
              :isProgressBarVisible="isProgressBarVisible"
              :rules="rules"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="12" md="3"> </v-col>
          <v-col cols="12" sm="12" md="2">
            <v-text-field
              dense
              outlined
              block
              label="Data de nascimento*"
              v-mask="'##/##/####'"
              :hint="'Formato: dd/mm/aaaa'"
              v-model="candidate.birthDate"
              :isProgressBarVisible="isProgressBarVisible"
              :rules="rules"
            ></v-text-field>
          </v-col>

          <v-col cols="12" sm="12" md="2">
            <v-select
              dense
              outlined
              :items="sexes"
              label="Sexo*"
              v-model="candidate.sex"
              :rules="rules"
            ></v-select>
          </v-col>

          <v-col cols="12" sm="12" md="2">
            <v-select
              dense
              outlined
              :items="civilStatuses"
              label="Estado Civil*"
              v-model="candidate.civilStatus"
              :rules="rules"
            ></v-select>
          </v-col>

          <v-col cols="12" sm="12" md="2">
            <v-select
              dense
              outlined
              :items="binaryChoice"
              label="Atualmente, está trabalhando?*"
              v-model="isEmployed"
              :rules="rules"
            ></v-select>
          </v-col>

          <v-col cols="12" sm="12" md="4">
            <v-text-field
              dense
              outlined
              v-if="isEmployed === true"
              block
              label="Nome da Empresa*"
              v-model="candidate.company"
              :isProgressBarVisible="isProgressBarVisible"
              :rules="rules"
            ></v-text-field>
          </v-col>

          <v-col cols="12" sm="12" md="2">
            <v-autocomplete
              dense
              outlined
              :items="countries"
              attach
              v-model="candidate.country"
              label="País de origem*"
              placeholder="Escolha um país"
              :rules="rules"
            ></v-autocomplete>
          </v-col>

          <v-col cols="12" sm="12" md="3" v-if="candidate.country === 'Brasil'">
            <v-text-field
              dense
              outlined
              block
              label="CPF*"
              v-mask="'###.###.###-##'"
              v-model="candidate.cpf"
              :isProgressBarVisible="isProgressBarVisible"
              :rules="rules"
            ></v-text-field>
          </v-col>

          <v-col cols="12" sm="12" md="5" v-if="candidate.country !== 'Brasil'">
            <v-select
              dense
              outlined
              :items="binaryChoice"
              label="Adquiriu nacionalidade brasileira?*"
              v-model="isForeignerWithBrazilianNacionality"
              :rules="rules"
            ></v-select>
          </v-col> 

          <v-col cols="12" sm="12" md="5" v-if="candidate.country !== 'Brasil'">
            <v-text-field
              dense
              outlined
              block
              label="RNE/CRNM*"
              v-model="candidate.foreignId"
              :isProgressBarVisible="isProgressBarVisible"
              :rules="rules"
            ></v-text-field>
          </v-col>
           <v-col cols="12" sm="12" md="12">
            <v-select
              dense
              outlined
              :items="tipoDeAluno"
              label="Tipo de Aluno*"
              v-model="candidate.tipoDeAluno"
              placeholder="(Escolha uma opção)"
            ></v-select>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "UpdatePersonalData",
  props: ["candidate"],
  components: {},
  data() {
    return {
      countries: [],
      civilStatuses: [
        "Solteiro(a)",
        "Casado(a)",
        "Separado(a)",
        "Viúvo(a)",
        "Outro",
      ],
      binaryChoice: [
        { text: "Sim", value: true },
        { text: "Não", value: false },
      ],
       tipoDeAluno: [
        "Portador de Diploma",
        "Regular em outra Instituição de Ensino Superior",
        "Português para Estrangeiro - PEC - G",
        "Convênio Instituição Pública",
        "Convênio Instituição Estrangeira",
        "Programa Integrado Formação (ALUNO DE GRADUAÇÃO DA UNICAMP)",
      ],
      sexes: ["Masculino", "Feminino", "Prefiro não informar"],
      isProgressBarVisible: false,
      isEmployed: false,
      isForeignerWithBrazilianNacionality: false,
      rules: [
        (p) => !!p || "O campo não pode ser vazio!",
      ]
    };
  },

  created() {
    this.getAsyncCountries();
  },

  methods: {
    ...mapActions("signUpCandidate", ["ActionGetCountries"]),

    async getAsyncCountries() {
      try {
        let res = await this.ActionGetCountries();
        this.countries = res.body;
      } catch (error) {
        console.error(error);
      }
    },
  },
};
</script>

<style>
</style>