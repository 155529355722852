<template>
  <div>
    <v-card outlined>
      <v-card-title class="amber lighten-5 mb-2"
        ><v-icon class="mr-2" color="amber">mail</v-icon>
        <h5 class="title">Dados para envio de correspondência</h5></v-card-title
      >
      <v-card-text class="grey lighten-5">
        <v-row wrap justify="start" align="end" dense>
          <v-col cols="12" sm="12" md="2">
            <v-text-field
              dense
              outlined
              block
              label="CEP*"
              v-mask="'#####-###'"
              v-model="candidate.primary_cep"
              @change="getAddressFromAPI(candidate.primary_cep, true)"
              :isProgressBarVisible="isProgressBarVisible"
            ></v-text-field>
          </v-col>

          <v-col cols="12" sm="12" md="2">
            <v-autocomplete
              dense
              outlined
              :items="ufs"
              attach
              item-text="UF"
              v-model="candidate.primary_state"
              label="UF*"
            >
              <template v-slot:no-data>
                <v-list-item>
                  <v-list-item-title> UF não encontrada. </v-list-item-title>
                </v-list-item>
              </template>
            </v-autocomplete>
          </v-col>

          <v-col cols="12" sm="12" md="8">
            <v-text-field
              dense
              outlined
              block
              label="Cidade*"
              v-model="candidate.primary_city"
              :isProgressBarVisible="isProgressBarVisible"
            ></v-text-field>
          </v-col>

          <v-col cols="12" sm="12" md="5">
            <v-text-field
              dense
              outlined
              block
              label="Rua/Avenida*"
              v-model="candidate.primary_street"
              :isProgressBarVisible="isProgressBarVisible"
            ></v-text-field>
          </v-col>

          <v-col cols="12" sm="12" md="2">
            <v-text-field
              dense
              outlined
              block
              label="Número*"
              ref="homeNumber"
              v-model="candidate.primary_home_number"
              v-mask="'#########'"
              :isProgressBarVisible="isProgressBarVisible"
            ></v-text-field>
          </v-col>

          <v-col cols="12" sm="12" md="5">
            <v-text-field
              dense
              outlined
              block
              label="Complemento"
              v-model="candidate.primary_complement"
              :isProgressBarVisible="isProgressBarVisible"
            ></v-text-field>
          </v-col>

          <v-col cols="12" sm="12" md="3">
            <v-text-field
              dense
              outlined
              block
              label="Telefone de contato*"
              v-mask="'(##) #####-####'"
              v-model="candidate.primary_cell_phone"
              :isProgressBarVisible="isProgressBarVisible"
            ></v-text-field>
          </v-col>

          <v-col cols="12" sm="12" md="3">
            <v-select
              dense
              outlined
              :items="binaryChoice"
              label="Gostaria de adicionar um endereço alternativo, por segurança?*"
              v-model="shouldAddAnotherAddress"
            ></v-select>
          </v-col>
          <v-col sm="0" md="6"></v-col>

          <v-col cols="12" sm="12" md="2">
            <v-text-field
              dense
              outlined
              block
              label="CEP*"
              v-mask="'#####-###'"
              v-model="candidate.secondary_cep"
              @change="getAddressFromAPI(candidate.secondary_cep, false)"
              :isProgressBarVisible="isProgressBarVisible"
            ></v-text-field>
          </v-col>

          <v-col cols="12" sm="12" md="1">
            <v-autocomplete
              dense
              outlined
              :items="ufs"
              attach
              item-text="UF"
              v-model="candidate.secondary_state"
              label="UF*"
            >
              <template v-slot:no-data>
                <v-list-item>
                  <v-list-item-title> UF não encontrada. </v-list-item-title>
                </v-list-item>
              </template>
            </v-autocomplete>
          </v-col>

          <v-col cols="12" sm="12" md="5">
            <v-text-field
              dense
              outlined
              block
              label="Cidade*"
              v-model="candidate.secondary_city"
              :isProgressBarVisible="isProgressBarVisible"
            ></v-text-field>
          </v-col>

          <v-col cols="12" sm="12" md="4">
            <v-text-field
              dense
              outlined
              block
              label="Rua/Avenida*"
              v-model="candidate.secondary_street"
              :isProgressBarVisible="isProgressBarVisible"
            ></v-text-field>
          </v-col>

          <v-col cols="12" sm="12" md="2">
            <v-text-field
              dense
              outlined
              block
              label="Número*"
              ref="homeNumber"
              v-model="candidate.secondary_home_number"
              v-mask="'#########'"
              :isProgressBarVisible="isProgressBarVisible"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="12" md="4">
            <v-text-field
              dense
              outlined
              block
              label="Complemento"
              v-model="candidate.secondary_complement"
              :isProgressBarVisible="isProgressBarVisible"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="12" md="2">
            <v-text-field
              dense
              outlined
              block
              label="Telefone*"
              v-mask="'(##) #####-####'"
              v-model="candidate.secondary_cell_phone"
              :isProgressBarVisible="isProgressBarVisible"
            ></v-text-field>
          </v-col>
          <v-col sm="0" md="4"></v-col>
         
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
export default {
  name: "UpdateAddress",
  props: ["candidate"],

  data() {
    return {
      ufs: [
        "AC",
        "AL",
        "AM",
        "AP",
        "BA",
        "CE",
        "DF",
        "ES",
        "GO",
        "MA",
        "MG",
        "MS",
        "MT",
        "PA",
        "PB",
        "PE",
        "PI",
        "PR",
        "RJ",
        "RN",
        "RO",
        "RR",
        "RS",
        "SC",
        "SE",
        "SP",
        "TO",
      ],
      binaryChoice: [
        { text: "Sim", value: true },
        { text: "Não", value: false },
      ],
     
      isProgressBarVisible: false,
      shouldAddAnotherAddress: false,
    };
  },

  create() {},

  methods: {},
};
</script>

<style>
</style>