<template>
  <div>
    <v-card outlined>
      <v-card-title class="green lighten-5 mb-2"
        ><v-icon class="mr-2" color="green">school</v-icon>
        <h5 class="title">Formação Acadêmica</h5></v-card-title
      >
      <v-card-text class="grey lighten-5">
        <v-row wrap justify="start" align="end" dense>
          <v-col cols="12" sm="12" md="4">
            <v-autocomplete
              dense
              outlined
              :items="nivelFormacao"
              attach
              item-text="nivelFormacao"
              v-model="candidate.nivelFormacao"
              label="Nível de Formação*"
              placeholder="(Escolha uma opção)"
              :rules="rules"
            >
              <template v-slot:no-data>
                <v-list-item>
                  <v-list-item-title> Nível inválido. </v-list-item-title>
                </v-list-item>
              </template>
            </v-autocomplete>
          </v-col>

          <v-col cols="12" sm="12" md="8">
            <v-combobox
              dense
              outlined
              :items="nomeDaInstituicao"
              attach
              item-text="Nome da Instituição"
              v-model="candidate.nomeDaInstituicao"
              label="Instituição de Ensino Superior"
              persistent-hint
              hint="Formato: Nome (SIGLA)"
              :rules="rules"
            >
            </v-combobox>
          </v-col>

          <v-col cols="12" sm="12" md="5">
            <v-text-field
              dense
              outlined
              block
              label="Curso*"
              v-model="candidate.cursoSuperior"
              :isProgressBarVisible="isProgressBarVisible"
              :rules="rules"
            ></v-text-field>
          </v-col>

          <v-col cols="12" sm="12" md="5">
            <v-text-field
              dense
              outlined
              block
              label="Cidade da Instituição*"
              v-model="candidate.cidadeInstituicao"
              :isProgressBarVisible="isProgressBarVisible"
              :rules="rules"
            ></v-text-field>
          </v-col>

          <v-col cols="12" sm="12" md="2">
            <v-autocomplete
              dense
              outlined
              :items="ufs"
              attach
              item-text="UF"
              v-model="candidate.estadoInstituicao"
              label="UF Instituição*"
              :rules="rules"
            >
              <template v-slot:no-data>
                <v-list-item>
                  <v-list-item-title> UF não encontrada. </v-list-item-title>
                </v-list-item>
              </template>
            </v-autocomplete>
          </v-col>

          <v-col cols="12" sm="12" md="3">
            <v-autocomplete
              dense
              outlined
              :items="countries"
              attach
              v-model="candidate.paisInstituicao"
              label="País Instituição*"
              :rules="rules"
            ></v-autocomplete>
          </v-col>

          <v-col cols="12" sm="12" md="3">
            <v-text-field
              dense
              outlined
              block
              label="Data de Conclusão/Previsão de Conclusão*"
              v-mask="'##/##/####'"
              :hint="'Formato: dd/mm/aaaa'"
              v-model="candidate.dataConclusao"
              :isProgressBarVisible="isProgressBarVisible"
              :rules="rules"
            ></v-text-field>
          </v-col>

          <v-col cols="12" sm="12" md="3">
            <v-text-field
              dense
              outlined
              block
              label="Número do Semestre Atual"
              v-mask="'##'"
              v-model="candidate.numeroSemestreAtual"
              :isProgressBarVisible="isProgressBarVisible"
              :rules="rules"
            ></v-text-field>
          </v-col>

          <v-col cols="12" sm="12" md="3">
            <v-select
              dense
              outlined
              :items="binaryChoice"
              label="Você já se inscreveu anteriormente para aluno especial no IC?*"
              v-model="jaSeInscreveuParaAlunoEspecial"
            ></v-select>
          </v-col>

          <v-col cols="12" sm="12" md="5">
            <v-select
              dense
              v-if="jaSeInscreveuParaAlunoEspecial"
              outlined
              :items="statusUltimoSemestreEspecial"
              label="Se sim, você:*"
              v-model="candidate.statusUltimoSemestreEspecial"
              placeholder="(Escolha uma opção)"
            ></v-select>
          </v-col>
          <v-col sm="0" md="7"></v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "UpdateAcademicInformation",
  props: ["candidate"],

  data() {
    return {
      nivelFormacao: [
        "Ensino Médio completo",
        "Ensino Médio incompleto",
        "Graduação completa",
        "Graduação incompleta",
        "Mestrado completo",
        "Mestrado incompleto",
        "Especialização completa",
        "Especialização incompleta",
        "Doutorado completo",
        "Doutorado incompleto",
        "Outro",
      ],
      statusUltimoSemestreEspecial: [
        "Cursou até o fim do semestre",
        "Não se matriculou",
        "Cancelou matrícula",
        "Abandonou disciplina",
      ],
      ufs: [
        "AC",
        "AL",
        "AM",
        "AP",
        "BA",
        "CE",
        "DF",
        "ES",
        "GO",
        "MA",
        "MG",
        "MS",
        "MT",
        "PA",
        "PB",
        "PE",
        "PI",
        "PR",
        "RJ",
        "RN",
        "RO",
        "RR",
        "RS",
        "SC",
        "SE",
        "SP",
        "TO",
      ],
      binaryChoice: [
        { text: "Sim", value: true },
        { text: "Não", value: false },
      ],
      nomeDaInstituicao: [],
      countries: [],
      isProgressBarVisible: false,
      jaSeInscreveuParaAlunoEspecial: false,
      rules: [(p) => !!p || "O campo não pode ser vazio!"],
    };
  },

  created() {
    this.getAsyncColleges();
    this.getAsyncCountries();
  },

  methods: {
    ...mapActions("signUpCandidate", [
      "ActionGetColleges",
      "ActionGetCountries",
    ]),

    async getAsyncColleges() {
      try {
        let res = await this.ActionGetColleges();
        this.nomeDaInstituicao = res.body;
      } catch (error) {
        console.error("Não foi possível buscar as intituições de ensino.");
      }
    },

    async getAsyncCountries() {
      try {
        let res = await this.ActionGetCountries();
        this.countries = res.body;
      } catch (error) {
        console.error(error);
      }
    },
  },
};
</script>

<style>
</style>